<template>
    <div
        class="bento-frame"
        :class="{
            'bento-frame--dark': props.dark,
            'bento-frame--image': !!$slots.image
        }"
    >
        <slot name="title">
            <h2
                v-if="title"
                class="bento-frame__title"
            >
                {{ title }}
            </h2>
        </slot>

        <div
            v-if="$slots.image"
            class="bento-frame__image"
        >
            <slot name="image" />
        </div>

        <div class="bento-frame__content">
            <slot />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    dark: {
        type: Boolean,
        default: false
    }
});

const backgroundColour = computed(() => {
    return props.dark ? 'var(--color-secondary)' : 'var(--color-lightest)';
});

const contentColour = computed(() => {
    return props.dark ? 'var(--color-lightest)' : 'var(--color-secondary)';
});
</script>

<style lang="less">
.bento-frame {
    display: flex;
    flex-direction: column;
    background-color: v-bind(backgroundColour); // stylelint-disable-line
    color: v-bind(contentColour); // stylelint-disable-line
    border-radius: 9px;
    overflow: hidden;
    width: 100%;

    picture, .graphic-device {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow: hidden;
        vertical-align: top;
    }

    div.graphic-device {
        color: var(--color-lightest);
    }
}

.bento-frame__image, .graphic-device {
    width: 100%;
}

.bento-frame--dark {
    div.graphic-device {
        color: var(--color-secondary);
    }
}

.bento-frame__title {
    .typography-label();
    border-bottom: 1px solid var(--color-muted-gray);

    @media @q-md-max {
        padding: .625rem .875rem;
    }

    @media @q-md-min {
        padding: 1.5rem 1.5rem .625rem;
    }
}

.bento-frame__content {
    @media @q-md-max {
        padding: 1.1875rem .8125rem;
    }

    @media @q-md-min {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1.5rem;
    }

    h2 {
        @media @q-md-max {
            margin-bottom: 1.75rem;
        }

        @media @q-md-min {
            margin-bottom: 2.75rem;
        }
    }
}

.bento-frame--even-padding .bento-frame__content {
    padding: var(--spacing-xs);

    @media @q-md-min {
        padding: var(--spacing-lg);
    }
}

.bento-frame--image {
    @media @q-md-min {
        display: flex;
        flex-direction: row;
        min-height: 27vw;

        img {
            max-height: 27vw;
            border-top-right-radius: 9px;
            border-bottom-right-radius: 9px;
        }
    }
}
</style>
